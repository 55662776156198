import React from 'react';
import firebase from '../firebase'

const db = firebase.firestore().collection("messages");
const validateEmail = (email) => {
    return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

class Contacts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        adding: false,
        name: "",
        email: "",
        subject: "",
        message: "",
    };
    this.addMessage = this.addMessage.bind(this);
  }

  clearForm = () => {
    document.getElementById("form").reset();
  }

  async addMessage(){
    if(!this.state.name || !this.state.email || !this.state.subject || !this.state.message){
        alert("Veuillez remplir tout les champs")
    }else if(this.state.email.indexOf("@") === -1){
        alert("Veuillez entrer une adresse mail valide");
    }else{
        this.setState({
            adding: true,
        })
        let time = new Date().getTime();
        let reference = time.toString(36).toUpperCase();
        db.doc(reference).set({
            "reference": reference,
            "name": this.state.name,
            "email": this.state.email,
            "subject": this.state.subject,
            "message": this.state.message
        }).then(() => {
            this.clearForm();
            this.setState({
                adding: false,
            });
            alert("Vous recevrez un message");
        }).catch((e) => {
            console.log("** ERROR **", e);
            this.setState({
                adding: false,
            });
        });
    }
  }

    render() {
        return (
            <footer id="footer" className="section-bg">
            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                        <div className="col-sm-6">
                          <div className="footer-info">
                            <h3>ANSER COMPANY</h3>
                            <p>Nous développons des solutions numériques innovantes pour les entreprises, des particuliers et les établissements d'enseignement</p>
                          </div>
                          <div className="footer-newsletter">
                            <h4>Newsletter</h4>
                            <p>Soyez parmi les premier(e)s à découvrir nos nouveaux produits et bénéficiez d’une réduction pour chaque service.</p>
                            <form>
                              <input type="email" name="email" /><input type="submit"  value="Souscrire" />
                            </form>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="footer-links">
                            <h4>Liens utiles</h4>
                            <ul>
                              <li><a href="#intro">Accueil</a></li>
                              <li><a href="#about">A propos de nous</a></li>
                              <li><a href="#services">Services</a></li>
                              {/* {<li><a href="#">Terms of service</a></li>
                              <li><a href="#">Privacy policy</a></li>} */}
                            </ul>
                          </div>
                          <div className="footer-links">
                            <h4>Contacts</h4>
                              <p>
                                  <strong>Canada:</strong> +1 (873) 339-3704 <br/>
                                  <strong>France:</strong> +33 744259177 <br/>
                                  <strong>Cameroun:</strong>+237 655287554 <br/>
                                  <br/>
                                  <strong>E-mail:</strong> contact@ansercompany.net<br/>
                              </p>
                          </div>
                          <div className="social-links">
                            {/* {<a href="#" className="twitter"><i className="fa fa-twitter"></i></a>} */}
                            <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
                            {/* {<a href="#" className="instagram"><i className="fa fa-instagram"></i></a>} */}
                            <a href="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form">
                      <h4>Envoyez nous un message</h4>
                      <p>Êtes-vous intéressé(e) par un service ? nous sommes prêts à répondre à toutes vos questions.</p>
                      <form id="form" className="contactForm">
                        <div className="form-group">
                          <input type="text" name="name" className="form-control" id="name" 
                            placeholder="Votre nom" data-rule="minlen:4" data-msg="Please enter at least 4 chars" 
                            onChange={(e) => {
                              this.setState({
                                  name: e.target.value
                              });
                          }}/>
                          <div className="validation"></div>
                        </div>
                        <div className="form-group">
                          <input type="email" className="form-control" name="email" id="email" 
                            placeholder="Votre adresse mail" data-rule="email" data-msg="Please enter a valid email"
                            onChange={(e) => {
                              this.setState({
                                  email: e.target.value
                              });
                            }}/>
                          <div className="validation"></div>
                        </div>
                        <div class="form-group">
                          <input type="text" className="form-control" name="subject" id="subject" placeholder="Sujet" data-rule="minlen:4" 
                            data-msg="Please enter at least 8 chars of subject" 
                            onChange={(e) => {
                              this.setState({
                                  subject: e.target.value
                              });
                            }}/>
                          <div className="validation"></div>
                        </div>
                        <div className="form-group">
                          <textarea className="form-control" name="message" id="message" rows="5" 
                            data-rule="required" data-msg="Please write something for us" placeholder="Message" 
                            onChange={(e) => {
                                this.setState({
                                    message: e.target.value
                                });
                            }}>
                          </textarea>
                          <div className="validation"></div>
                        </div>
                        <div id="sendmessage">Your message has been sent. Thank you!</div>
                        <div id="errormessage"></div>
                        <div className="text-center"><button type="submit" title="Send Message" onClick={this.addMessage} disabled={this.state.adding}>Envoyez</button></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="copyright">
                &copy; Copyright 2024 <strong>Anser Company</strong>. Tous droits réservés
              </div>
            </div>
          </footer>
        );
    }
}

export default Contacts;