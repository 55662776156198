import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage'
import 'firebase/auth';
import 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCANEkAxIU4RqUawcEgpO-oG6pm5RYuZy8",
    authDomain: "front-office-55df7.firebaseapp.com",
    projectId: "front-office-55df7",
    storageBucket: "front-office-55df7.appspot.com",
    messagingSenderId: "954799292490",
    appId: "1:954799292490:web:dfbecc1c2bf5795f2ea6b3",
    measurementId: "G-2JMXVM9YGJ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//firebase.firestore().settings(settings);

export default firebase;