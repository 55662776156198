import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/home';

class App extends React.Component {

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "../js/main.js";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <Home/>
    );
  }
}

export default App;
