import React from 'react';

const About = () => {
    return (
        <section id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-6">
                        <div className="about-img">
                            <img src="img/aboutus1.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="about-content">
                            <h2>A propos de nous</h2>

                            <p style={{textAlign:"justify"}} ><b>ANSER</b> est une agence de transformation digitale, de conseil et de formation numérique qui
                                met l’accent sur la qualité, l’innovation la rapidité et la satisfaction client grâce à une équipe
                                internationale d’expert en data scientists, développement logiciel et analyse à travers l’Europe
                                et l’Afrique.</p>
                            <p style={{textAlign:"justify"}}>Au fil des années, <b>ANSER</b> a été en mesure de développer des systèmes performants et
                                uniques dans plusieurs secteurs d’activité tout en permettant à ses clients d’obtenir une
                                meilleure présence globale sur le Web.</p>
                            <ul>
                                <li><i className="ion-android-checkmark-circle"></i><i>Notre vision  :</i> <b>Booster les valeurs des entreprises.</b></li>
                                <li><i className="ion-android-checkmark-circle"></i> <i>Notre mission :</i> <b style={{textAlign:"justify"}}>Mettre en place des systèmes d’information performants, innovantes et alignés
                                    à la stratégie globale de l’entreprise afin d’accroitre ses performances. </b></li>
                                <li><i className="ion-android-checkmark-circle"></i> <i>Nos valeurs :</i> <b>Professionnalisme, Efficacité, Intégrité</b></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;