import React from 'react';

class Team extends React.Component {
    render() {
        return (
            <section id="team" className="section-bg">
              <div className="container">
                <div className="section-header">
                  <h3>Equipe</h3>
                  <p>Nous travaillons à améliorer votre quotidien</p>
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-3 col-md-6 wow fadeInUp">
                    <div className="member">
                      <img src="img/jordan.png" className="img-fluid" alt="" />
                      <div className="member-info">
                        <div className="member-info-content">
                          <h4>Jordan Foute</h4>
                          <span>co-founder</span>
                          <div className="social">
                            <a target="_blank" href="https://www.linkedin.com/in/jordan-fout%C3%A9-3715711a7/"><i className="fa fa-linkedin"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="member">
                      <img src="img/jocelin.png" className="img-fluid" alt="" />
                      <div className="member-info">
                        <div className="member-info-content">
                          <h4>Jocelin Fowe</h4>
                          <span>co-founder</span>
                          <div className="social">
                            <a target="_blank" href="https://www.linkedin.com/in/jocelin-fowe-01a99a177/"><i className="fa fa-linkedin"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="member">
                      <img src="img/kevin.jpg" className="img-fluid" alt="" />
                      <div className="member-info">
                        <div className="member-info-content">
                          <h4>Kevin Nana</h4>
                          <span>co-founder</span>
                          <div className="social">
                            <a target="_blank" href="https://www.linkedin.com/in/kevin-nana"><i className="fa fa-linkedin"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        );
    }
}

export default Team;