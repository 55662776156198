import React from 'react';

class Services extends React.Component {
    render() {
        return (
            <section id="services" className="section-bg">
              <div className="container">
                <header className="section-header">
                  <h3>Services</h3>
                  <p>Anser Company répond à vos enjeux à travers 6 pôles d'expertise</p>
                </header>
                <div className="row">
                  <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-duration="1.4s">
                    <div className="box">
                      <div className="icon" style={{background: "#fceef3;"}}><i className="ion-code ico" style={{color: "#ff689b;"}}></i></div>
                      <h4 className="title"><a href="">Développement de Logiciel et Applications Web</a></h4>
                      <p className="description">Peu importe votre besoin, site pour entreprise, plates-formes de e-commerce, système de gestion de contenu, et bien d’autres types d'applications, Anser vous offre le service de développement de logiciels ou applications de qualité pour répondre à ce besoin. Nous travaillons avec vous pour comprendre vos objectifs commerciaux et créer des solutions sur mesure qui améliorent votre productivité, votre rentabilité et votre compétitivité.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-duration="1.4s">
                    <div className="box">
                      <div className="icon" style={{background: "#fff0da;"}}><i className="ion-android-people ico" style={{color: "#e98e06;"}}></i></div>
                      <h4 className="title"><a href="">Consultation informatique et sous-traitance</a></h4>
                      <p className="description">Notre équipe de professionnels expérimentés est à votre disposition pour vous offrir des conseils personnalisés et une assistance technique rapide et efficace. Que vous ayez besoin de maintenir une application existante ou que vous souhaitiez mettre en place de nouvelles technologies, nous sommes là pour vous aider à atteindre vos objectifs.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                    <div className="box">
                      <div className="icon" style={{background: "#e6fdfc;"}}><i className="ion-android-cloud ico" style={{color: "#3fcdc7;"}}></i></div>
                      <h4 className="title"><a href="">Hébergement d’applications web, mobile et maintenance</a></h4>
                      <p className="description">Nous hébergeons votre projet et vous bénéficiez d'un accompagnement personnalisé, ainsi que d'un suivi régulier pour garantir un bon fonctionnement de votre application. Nous nous engageons à vous fournir une solution adaptée à vos besoins pour que votre projet en ligne soit un succès.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                    <div className="box">
                      <div className="icon" style={{background: "#eafde7;"}}><i className="ion-ribbon-b ico" style={{color: "#41cf2e;"}}></i></div>
                      <h4 className="title"><a href="">Formations informatiques</a></h4>
                      <p className="description">Nous proposons des formations adaptées à vos besoins et à votre niveau. Notre équipe de formateurs expérimentés vous accompagnera tout au long de votre parcours de formation pour vous permettre de maîtriser les technologies de développement d’applications Web et Mobile ainsi qu'aux technologies d’intelligence artificielle.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.2s" data-wow-duration="1.4s">
                    <div className="box">
                      <div className="icon" style={{background: "#e1eeff;"}}><i className="ion-stats-bars ico" style={{color: "#2282ff;"}}></i></div>
                      <h4 className="title"><a href="">Analyse et traitement  de données</a></h4>
                      <p className="description">A l’aide des techniques avancées d’analyse de données et d’IA, nous transformons vos données en informations utiles et pertinentes pour votre business ou entreprise. Nous pouvons vous aider à découvrir des tendances cachées, à identifier les opportunités de croissance et à prendre des décisions éclairées.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.2s" data-wow-duration="1.4s">
                    <div className="box">
                      <div className="icon" style={{background: "#e1eeff;"}}><i className="ion-social-buffer ico" style={{color: "#2282ff;"}}></i></div>
                      <h4 className="title"><a href="">Conception graphique</a></h4>
                      <p className="description">Nous offrons des services de design haut de gamme qui vous permettront d'attirer et de fidéliser votre clientèle. Notre équipe de designers expérimentés travaille en étroite collaboration avec vous pour comprendre vos besoins et votre vision. Nous nous engageons à créer des designs personnalisés qui reflètent l'essence de votre entreprise et qui attirent l'attention de votre public cible.</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        );
    }
}

export default Services;