import React, {useEffect} from 'react';
import Header from '../sections/header';
import Intro from '../sections/intro';
import About from '../sections/about';
import Services from '../sections/services';
import Why from '../sections/why';
import Action from '../sections/action';
import Portfolio from '../sections/portfolio';
import Pricing from '../sections/pricing';
import Contacts from '../sections/contacts';
import Faq from '../sections/faq';
import Client from '../sections/clients';
import Testimonials from '../sections/testimonials';
import Team from '../sections/team';
import {Helmet} from "react-helmet";


const Home = () => {

    const helm = (
        <Helmet>
            <script src="js/main.js"></script>
            <script src="lib/counterup/counterup.min.js"></script>
        </Helmet>
    )

    useEffect(()=>{


    },[])

    return (
        <>
            {helm}
            <body>
            <Header/>
            <Intro/>

            <main id="main">
                <About/>
                <Services/>
                <Why/>
                {/*<Action/>*/}
                {/*<Portfolio/>*/}
                {/*<Testimonials/>*/}
                <Team/>
                {/* {<Client/>} */}
                {/* {<Pricing/>} */}
                {/* {<Faq/>} */}
            </main>
            <Contacts/>
            <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>
            </body>
        </>
    );
}

export default Home;