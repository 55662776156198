import React from 'react';

const Why = () => {
  return (
      <section id="why-us" className="wow fadeIn">
        <div className="container-fluid">
          <header className="section-header">
            <h3>Pourquoi nous choisir?</h3>
          </header>
          <div className="row">
            <div className="col-lg-6">
              <div className="why-us-img">
                <img src="img/why.jpg" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-us-content">
                <p>

                </p>
                <div className="features wow bounceInUp clearfix">
                  <i className="fa fa-diamond" style={{color: "#f058dc;"}}></i>
                  <h4>Consultants expérimentés</h4>
                  <p>Nous disposons de consultants issus d'écoles d'ingénieur, avec une expérience confirmée dans des grands cabinet de conseil et d'audit.</p>
                </div>
                <div className="features wow bounceInUp clearfix">
                  <i className="fa fa-object-group" style={{color: "#ffb774;"}}></i>
                  <h4>Approche agile</h4>
                  <p>Toute notre méthodologie de travail est basée sur l'agilité afin d'éviter des effets tunel et satisfaire le client.</p>
                </div>
                <div className="features wow bounceInUp clearfix">
                  <i className="fa fa-language" style={{color: "#589af1;"}}></i>
                  <h4>Agence multisectorielle</h4>
                  <p>Anser incarne des valeurs fortes lui permettant de se prononcer de façon digitale sur n'importe quelle problématique posée.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="container">
          <div className="row counters">
            <div className="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">274</span>
              <p>Clients</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">421</span>
              <p>Projects</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">1,364</span>
              <p>Hours Of Support</p>
            </div>
            <div className="col-lg-3 col-6 text-center">
              <span data-toggle="counter-up">18</span>
              <p>Hard Workers</p>
            </div>
          </div>
        </div>*/}
      </section>
  );
}

export default Why;