import React from 'react';

class Header extends React.Component {
    render() {
        return (
            <header id="header">
            <div id="topbar">
              <div className="container">
                <div className="social-links">
                  <a href="#" className="twitter"><i className="fa fa-twitter"></i></a>
                  <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
                  <a href="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
                  <a href="#" className="instagram"><i className="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="logo float-left">
                <a href="#header"  className="scrollto"><img src="img/logo.png"  alt="" className="img-fluid" /></a>
              </div>
              <nav className="main-nav float-right d-none d-lg-block">
                <ul>
                  <li className="active"><a href="#intro">Accueil</a></li>
                  <li><a href="#about">A propos</a></li>
                  <li><a href="#services">Services</a></li>
                  <li><a href="#team">Equipe</a></li>
                  <li><a href="#footer">Contact</a></li>
                    {/*<li className="drop-down"><a href="">Drop Down</a>
                        <ul>
                            <li><a href="#">Drop Down 1</a></li>
                            <li className="drop-down"><a href="#">Drop Down 2</a>
                                <ul>
                                    <li><a href="#">Deep Drop Down 1</a></li>
                                    <li><a href="#">Deep Drop Down 2</a></li>
                                    <li><a href="#">Deep Drop Down 3</a></li>
                                    <li><a href="#">Deep Drop Down 4</a></li>
                                    <li><a href="#">Deep Drop Down 5</a></li>
                                </ul>
                            </li>
                            <li><a href="#">Drop Down 3</a></li>
                            <li><a href="#">Drop Down 4</a></li>
                            <li><a href="#">Drop Down 5</a></li>
                        </ul>
                    </li>*/}
                    <li className="drop-down"><a href="">Langue</a>
                        <ul>
                            <li><a href="#">Français</a></li>
                            <li><a href="#">Anglais</a></li>
                        </ul>
                    </li>
                </ul>
              </nav>
            </div>
          </header>
        );
    }
}

export default Header;