import React from 'react';

class Intro extends React.Component {
    render() {
        return (
            <section id="intro" className="clearfix">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
                <div className="col-md-6 intro-info order-md-first order-last">
                  <h2>ANSER COMPANY <br/> <span> votre partenaire de transformation digitale !</span></h2>
                  <div>
                    <a href="#about" className="btn-get-started scrollto">En savoir plus</a>
                  </div>
                </div>
                <div className="col-md-6 intro-img order-md-last order-first">
                  <img src="img/intro-img.svg" alt="" className="img-fluid"/>
                </div>
              </div>
            </div>
          </section>
        );
    }
}

export default Intro;